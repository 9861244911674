/**
 * Switch between Featured and Archived views
*/

const switchView = (page) => {
  let featured = page.container.querySelector('.featured');
  const archived = page.container.querySelector('.archived');
  const projectNav = page.container.querySelector('.projects-nav-list');
  const sliderNav = page.container.querySelector('.slider-nav-list');
  const featuredLink = projectNav.querySelector('li:first-child .projects-nav-link');
  const archivedLink = projectNav.querySelector('li:last-child .projects-nav-link');

  featuredLink.addEventListener('click', function () {
    if (!this.classList.contains('is-active')) {
      this.classList.add('is-active');
      featured.classList.add('is-active');
    }

    if (archivedLink.classList.contains('is-active') && archived.classList.contains('is-active')) {
      archived.classList.remove('is-active');
      archivedLink.classList.remove('is-active');
      sliderNav.style.display = '';
    }
  });

  archivedLink.addEventListener('click', function () {
    if (!this.classList.contains('is-active')) {
      this.classList.add('is-active');
      archived.classList.add('is-active');
    }

    if (featuredLink.classList.contains('is-active') && featured.classList.contains('is-active')) {
      featured.classList.remove('is-active');
      featuredLink.classList.remove('is-active');
      sliderNav.style.display = 'none';
    }
  });

  // media query event handler
  if (window.matchMedia) {
    const xsMQ = window.matchMedia('(max-width: 575.98px)');
    xsMQ.addListener(selectContainer);
    selectContainer(xsMQ);
  }

  function selectContainer (xsMQ) {
    if (xsMQ.matches) {
      featured = document.querySelector('.featured');
    }
  }
};

export { switchView };
