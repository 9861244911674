/**
 * © Antoine Puel — 2020
 */

/**
 * Import dependencies
 */
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import lazySizes from 'lazysizes';

/**
 * Custom modules
 */
import { toggle } from './js/Utils/Collapse';
import { switchView } from './js/Components/SwitchView';
import * as Slider from './js/Components/Slider';

/**
 * LazySizes Options
 */
lazySizes.cfg.lazyClass = 'lazy';

let sliderIndex;

/**
* From Project, at first browser load
*/
const fromProjectFirstLoad = {
  name: 'from-project',
  from: {
    custom: () => {
      return window.location.pathname.indexOf('projects') === 1;
    }
  },
  once ({ next }) {
    disableScroll();
    toggleProjectVisibility();
    addActiveClassToMenuLinks();
    setOverlayBackground(next);
    // Back button redirects to Home page
    const backButton = next.container.querySelector('.back-button');
    backButton.addEventListener('click', function () {
      leaveProjectPage(next);
      setTimeout(() => barba.go('/'), 250);
    });

    // ESC key redirects to Home page
    document.onkeydown = function (evt) {
      evt = evt || window.event;
      if (evt.key === 'Escape') {
        leaveProjectPage(next);
        setTimeout(() => barba.go('/'), 250);
      }
    };
  }
};

/**
* From Project to All
*/
const fromProjectToAll = {
  name: 'from-project-to-all',
  enter ({ current, next }) {
    const oldHomeInfos = current.container.querySelector('.general-infos');
    const newHomeInfos = next.container.querySelector('.general-infos');
    const newHomeIntroduction = next.container.querySelector('.introduction');
    let isOldHomeInfosVisible;
    if (oldHomeInfos) {
      isOldHomeInfosVisible = oldHomeInfos.classList.contains('is-visible');
    }
    // Set the visibility of Home infos based on current Homepage
    if (oldHomeInfos && isOldHomeInfosVisible) {
      newHomeInfos.classList.add('is-visible');
      newHomeInfos.style.opacity = '1';
      newHomeIntroduction.classList.add('is-active');
    }

    // Load proper Featured / Archived projects
    const oldProjectsWrapper = current.container.querySelector('.projects-wrapper');
    const newProjectsWrapper = next.container.querySelector('.projects-wrapper');
    const contentWrapper = next.container.querySelector('.content-wrapper');
    // Remove Projects Wrapper from Home
    newProjectsWrapper.parentNode.removeChild(newProjectsWrapper);
    // Load Projects Wrapper from Contact into Home
    if (oldProjectsWrapper) {
      contentWrapper.append(oldProjectsWrapper);
    }
  },
  from: {
    namespace: [
      'project'
    ]
  },
  to: {
    namespace: [
      'home',
      'contact'
    ]
  }
};

/**
* From Home to Contact
*/
const fromHomeToContact = {
  name: 'from-home-to-contact',
  enter ({ current, next }) {
    const homeProjectsWrapper = current.container.querySelector('.projects-wrapper');
    const contactProjectsWrapper = next.container.querySelector('.projects-wrapper');
    const contentWrapper = next.container.querySelector('.content-wrapper');
    // Remove Projects Wrapper from Contact
    contactProjectsWrapper.parentNode.removeChild(contactProjectsWrapper);
    // Load Projects Wrapper from Home into Contact
    if (homeProjectsWrapper) {
      contentWrapper.append(homeProjectsWrapper);
    }
  },
  from: {
    namespace: [
      'home'
    ]
  },
  to: {
    namespace: [
      'contact'
    ]
  }
};

const fromContactToHome = {
  name: 'from-contact-to-home',
  enter ({ current, next }) {
    const contactProjectsWrapper = current.container.querySelector('.projects-wrapper');
    const homeProjectsWrapper = next.container.querySelector('.projects-wrapper');
    const contentWrapper = next.container.querySelector('.content-wrapper');
    // Remove Projects Wrapper from Home
    homeProjectsWrapper.parentNode.removeChild(homeProjectsWrapper);
    // Load Projects Wrapper from Contact into Home
    if (contactProjectsWrapper) {
      contentWrapper.append(contactProjectsWrapper);
    }
  },
  from: {
    namespace: [
      'contact'
    ]
  },
  to: {
    namespace: [
      'home'
    ]
  }
};

/**
* From all pages to Project
*/
const fromAllToProject = {
  name: 'from-all-to-project',
  enter ({ current, next }) {
    sliderIndex = Slider.getIndex(current);

    disableScroll();
    setTimeout(() => toggleProjectVisibility(), 50);
    addActiveClassToMenuLinks();
    setOverlayBackground(next);
    removeContent(current, next);
    appendContent(current, next);

    // Go to previous page
    const backButton = next.container.querySelector('.back-button');
    backButton.addEventListener('click', function () {
      leaveProjectPage(next);
      setTimeout(() => barba.go(barba.history.previous.url), 250);
    });

    // Go to previous page with ESC key
    document.onkeydown = function (evt) {
      evt = evt || window.event;
      if (evt.key === 'Escape') {
        leaveProjectPage(next);
        setTimeout(() => barba.go(barba.history.previous.url), 250);
      }
    };
  },
  after ({ next }) {
    Slider.setIndex(next, sliderIndex);
  },
  from: {
    namespace: [
      'home',
      'contact'
    ]
  },
  to: {
    namespace: [
      'project'
    ]
  }
};

/**
 * Barba Options
 */
barba.use(barbaPrefetch);

barba.hooks.beforeEnter(({ next }) => {
  enableScroll();
  Slider.init(next);
});

barba.init({
  transitions: [
    fromProjectFirstLoad,
    fromAllToProject,
    fromProjectToAll,
    fromHomeToContact,
    fromContactToHome
  ],
  /**
    * Home View
  */
  views: [{
    namespace: 'home',
    afterEnter ({ next }) {
      switchView(next);
      setAttributesToExternalLinks(next);
      // Show Home Infos section
      const buttonMore = next.container.querySelector('.button-more');
      const homeInfos = next.container.querySelector('.general-infos');
      const introduction = next.container.querySelector('.introduction');
      buttonMore.addEventListener('click', function () {
        toggle(homeInfos);
        if (introduction.classList.contains('is-active')) {
          setTimeout(() => {
            introduction.classList.remove('is-active');
          }, 250);
        } else {
          introduction.classList.add('is-active');
        }
      });
    }
  },
  {
    namespace: 'contact',
    afterEnter ({ next }) {
      switchView(next);
      setAttributesToExternalLinks(next);
    }
  },
  {
    namespace: 'project',
    afterEnter ({ next }) {
      // Show Project Infos section
      const buttonMore = next.container.querySelector('.button-more-project');
      const projectInfos = next.container.querySelector('.project-infos');
      buttonMore.addEventListener('click', function () {
        toggle(projectInfos);
      });
    }
  }]
});

/**
 * Enable and disable body and HTML scroll
*/
const enableScroll = () => {
  document.body.style.overflow = '';
  document.querySelector('html').style.overflowY = '';
};

const disableScroll = () => {
  document.body.style.overflow = 'hidden';
  setTimeout(function () {
    document.querySelector('html').style.overflowY = 'hidden';
  }, 250);
};

/**
 * Add proper attributes to external links
*/
const setAttributesToExternalLinks = (page) => {
  const externalLinks = page.container.querySelectorAll('.archived a');
  if (externalLinks) {
    externalLinks.forEach((link) => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'external noopener');
    });
  }
};

/**
 * Enable and disable background overlay
*/
const setOverlayBackground = (page) => {
  page.container.classList.add('is-active');
};

const resetOverlayBackground = (page) => {
  page.container.classList.remove('is-active');
};

/**
 * Append current container to the wrapper
*/
const appendContent = (currentPage, nextPage) => {
  const introduction = currentPage.container.querySelector('.introduction');
  const homeInfos = currentPage.container.querySelector('.general-infos');
  const contentWrapper = nextPage.container.querySelector('.content-wrapper');
  if (homeInfos) contentWrapper.prepend(homeInfos);
  if (introduction) contentWrapper.prepend(introduction);
};

/**
 * Remove default homepage content of Project page
*/
const removeContent = (currentPage, nextPage) => {
  const introduction = nextPage.container.querySelector('.introduction');
  introduction.parentNode.removeChild(introduction);
};

/**
 * Toggle project panel visibility
*/
const toggleProjectVisibility = () => {
  const asidePanel = document.querySelector('.project-panel');
  asidePanel.classList.toggle('is-visible');
};

/**
 * Add active class to all menu links
*/
const addActiveClassToMenuLinks = () => {
  const menuTitles = document.querySelectorAll('.menu-navlist-link');
  menuTitles.forEach(title => title.classList.add('is-active'));
};

/**
 * All steps to properly leave project page
*/
const leaveProjectPage = (page) => {
  enableScroll();
  resetOverlayBackground(page);
  toggleProjectVisibility();
};
