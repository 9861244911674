// Import Swiper and modules
import { Swiper, Navigation } from 'swiper';
// Install modules
Swiper.use([Navigation]);

/**
 * Initialize Slider
*/
const init = (nextPage) => {
  const xsMQ = window.matchMedia('(max-width: 575.98px)');
  const previousButton = nextPage.container.querySelector('#previous');
  const nextButton = nextPage.container.querySelector('#next');
  const featured = nextPage.container.querySelector('.featured');

  const featuredSlider = new Swiper(featured, {
    // init: false,
    slidesPerView: 'auto',
    allowTouchMove: false,
    spaceBetween: 16,
    navigation: {
      nextEl: nextButton,
      prevEl: previousButton
    },
    slideClass: 'featured-project',
    slideActiveClass: 'featured-project-active',
    slideNextClass: 'featured-project-next',
    slidePrevClass: 'featured-project-prev'
  });

  // Listen to the Slider initialisation
  if (window.matchMedia) {
    xsMQ.addListener(initSlider);
    initSlider(xsMQ);
  }

  function initSlider (mediaQuery) {
    /* On Small Screen */
    if (mediaQuery.matches) {
      // featuredSlider.init();
      featuredSlider.slideTo(0, 0);
    //* On large Screen */
    } else {
      featuredSlider.on('init', function () {
        featuredSlider.changeDirection('horizontal');
      });
      featuredSlider.init();
    }
  }
};

const getIndex = (currentPage) => {
  const currentSlider = currentPage.container.querySelector('.featured').swiper;
  const currentIndex = currentSlider.activeIndex;
  return currentIndex;
};

const setIndex = (nextPage, index) => {
  const nextSlider = nextPage.container.querySelector('.featured').swiper;
  if (nextSlider) nextSlider.slideTo(index, 0);
};

export { init, getIndex, setIndex };
